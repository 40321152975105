<template>
  <div>
    <div class="" v-for="menu_entry in menu" :key="menu_entry.name">
      <b-row class="mb-2">
        <b-button variant="outline-primary" :to="{name: menu_entry.name}">{{menu_entry.name}}</b-button>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'mainMenu',
  data(){
    return{
        menu: [
                { name: 'Registro Consular'},
                { name: 'Estado de su Prórroga'},
                // { name: 'Notificar Cita'}
                // { name: 'Planillas'}
              ]
    }
  }
}
</script>