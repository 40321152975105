<template>
  <div id="app">
    <mainHeader title="Embajada de la República Bolivariana de Venezuela en Australia"/>
    <b-row class="m-3">
      <b-col cols="2">
        <mainMenu/>
      </b-col>
      <b-col cols="10">
        <router-view/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import mainHeader from '@/components/parts/mainHeader.vue'
import mainMenu from '@/components/parts/mainMenu.vue'
// import box from '@/components/parts/box.vue'

export default {
  name: 'App',
  components: {
    mainHeader,
    mainMenu,
    // box
  }
}
</script>